<template>
    <v-icon>{{icon}}</v-icon>
</template>

<script>

    export default {
        name: "VideoIcon", 
        props: {
            value: {type: String }
        },
        computed: {
            icon() {
				if (this.value == "google meet") {
					return "mdi-google";
				}
				if (this.value == "zoom") {
					return "mdi-alpha-z-circle";
				}
				if (this.value == "ms teams") {
					return "mdi-microsoft-teams";
				}
				if (this.value == "skype") {
					return "mdi-skype";
				}
				return "mdi-message-video";
			}
        }
    }
</script>