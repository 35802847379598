<template>
	<mw-dialog
		v-model="dialog"
		cancel-btn
		
	>
		<template v-slot:button>
			<v-btn @click="dialog = true" x-small text>
				View Submission
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			<p v-for="consent in consents" :key="consent.id">
				<edit-consent :value="consent.id"></edit-consent>
				<!-- <single-response
					:survey-id="consent.survey"
					:data="consent.data"
				></single-response> -->
			</p>
		</template>
        <template v-slot:dialog-buttons>
            <v-btn small color="primary" @click="markConsented">Mark Consented</v-btn>
        </template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
// import SingleResponse from "@c/responses/SingleResponse";
import EditConsent from "@c/consent/EditConsent"
export default {
	name: "CheckConsent",
	props: {
		value: { type: String },
	},
	data: () => {
		return {
			dialog: null,
		};
	},
    computed: {
		consents() {
			return this.$store.getters.getByKey(
				"consents",
				"appointment_participant",
				this.value
			);
		},
	},
	methods: {
		markConsented() {
			this.$store.dispatch("appointmentParticipants/patch", {
				id: this.value,
				consented: true,
			});
		},
	},
	components: {
		MwDialog,
        // SingleResponse,
		EditConsent
	},	
};
</script>
