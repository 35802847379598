<template>
	<div>
		<check-consent v-if="consents.length" :value="value"></check-consent>
		<chase-consent v-else :value="value"></chase-consent>
	</div>
</template>

<script>
import CheckConsent from "@c/consent/CheckConsent";
import ChaseConsent from "@c/consent/ChaseConsent";
export default {
	name: "ChaseOrCheckConsent",
	props: {
		value: { type: String },
	},

	computed: {
		consents() {
			return this.$store.getters.getByKey(
				"consents",
				"appointment_participant",
				this.value
			);
		},
	},
	components: {
		CheckConsent,
		ChaseConsent,
	},
	created() {
        // console.log( this.value )
		this.$store.dispatch("consents/fetchByAppointmentParticipant", this.value )
	},
};
</script>
