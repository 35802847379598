var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-row',{staticClass:"mb-4"},[_c('v-btn-toggle',{staticClass:"mr-3",model:{value:(_vm.days),callback:function ($$v) {_vm.days=$$v},expression:"days"}},[_c('v-btn',{attrs:{"value":3}},[_vm._v("3 Days")]),_c('v-btn',{attrs:{"value":10}},[_vm._v("10 Days ")])],1),_c('v-checkbox',{staticClass:"mr-3",attrs:{"hide-details":"","label":"Unconfirmed"},model:{value:(_vm.unconfirmed_only),callback:function ($$v) {_vm.unconfirmed_only=$$v},expression:"unconfirmed_only"}}),_c('v-checkbox',{staticClass:"mr-3",attrs:{"hide-details":"","label":"Missing Links"},model:{value:(_vm.unlinked_only),callback:function ($$v) {_vm.unlinked_only=$$v},expression:"unlinked_only"}}),_c('v-checkbox',{staticClass:"mr-3",attrs:{"hide-details":"","label":"Unconsented"},model:{value:(_vm.unconsented_only),callback:function ($$v) {_vm.unconsented_only=$$v},expression:"unconsented_only"}})],1),_c('v-text-field',{attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"search":_vm.search},scopedSlots:_vm._u([{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start.toDate().toLocaleDateString([], { hour: "2-digit", minute: "2-digit", }))+" "),(item.is_soon)?_c('chase-button',{attrs:{"x-small":""},model:{value:(item.appointment_participant),callback:function ($$v) {_vm.$set(item, "appointment_participant", $$v)},expression:"item.appointment_participant"}}):_vm._e()]}},{key:"item.medium",fn:function(ref){
var item = ref.item;
return [_c('video-icon',{attrs:{"value":item.video_medium}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(['recruited', 'provisional'].includes(item.status))?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.sendConfirmation(
						item.appointment_participant,
						item.project_id
					)}}},[_vm._v("Send Confirmation")]):_c('small',[_vm._v(_vm._s(item.status))])]}},{key:"item.project",fn:function(ref){
					var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("projects/" + (item.project_id))}},[_vm._v(" "+_vm._s(item.project)+" ")])]}},{key:"item.user",fn:function(ref){
					var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("users/" + (item.user_id))}},[_vm._v(" "+_vm._s(item.user)+" ")])]}},{key:"item.have_link",fn:function(ref){
					var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.have_link ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.have_link ? "mdi-check-circle" : "mdi-close"))])]}},{key:"item.consented",fn:function(ref){
					var item = ref.item;
return [_c('div',[(item.consented)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]):_c('chase-or-check-consent',{attrs:{"value":item.appointment_participant}})],1)]}},{key:"item.last_email",fn:function(ref){
					var item = ref.item;
return [(item.user)?_c('last-comms',{attrs:{"value":item.user_id}}):_vm._e()]}},{key:"item.last_sms",fn:function(ref){
					var item = ref.item;
return [(item.user)?_c('last-comms',{attrs:{"value":item.user_id,"type":"message"}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }