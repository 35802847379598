<template>
	<v-sheet>
		<v-row class="mb-4">
			<v-btn-toggle v-model="days" class="mr-3">
				<v-btn :value="3">3 Days</v-btn>
				<v-btn :value="10">10 Days </v-btn>
			</v-btn-toggle>

			<v-checkbox hide-details class="mr-3"
				v-model="unconfirmed_only"
				label="Unconfirmed"
			></v-checkbox>
			<v-checkbox hide-details class="mr-3"
				v-model="unlinked_only"
				label="Missing Links"
			></v-checkbox>
			<v-checkbox hide-details class="mr-3"
				v-model="unconsented_only"
				label="Unconsented"
			></v-checkbox>
		</v-row>

		<v-text-field v-model="search" label="Search"></v-text-field>
		<v-data-table :items="items" :headers="headers" :search="search">
			<template v-slot:item.start="{ item }">
				{{
					item.start.toDate().toLocaleDateString([], {
						hour: "2-digit",
						minute: "2-digit",
					})
				}}
				<chase-button x-small v-if="item.is_soon" v-model="item.appointment_participant"></chase-button>
			</template>
			<template v-slot:item.medium="{ item }">
				<video-icon :value="item.video_medium"></video-icon>
			</template>
			<template v-slot:item.status="{ item }">
				<v-btn
					x-small
					color="primary"
					v-if="['recruited', 'provisional'].includes(item.status)"
					@click="
						sendConfirmation(
							item.appointment_participant,
							item.project_id
						)
					"
					>Send Confirmation</v-btn
				>
				<small v-else>{{ item.status }}</small>
			</template>

			<template v-slot:item.project="{ item }">
				<a target="_blank" :href="`projects/${item.project_id}`">
					{{ item.project }}
				</a>
			</template>
			<template v-slot:item.user="{ item }">
				<a target="_blank" :href="`users/${item.user_id}`">
					{{ item.user }}
				</a>
			</template>
			<template v-slot:item.have_link="{ item }">
				<v-icon :color="item.have_link ? 'success' : 'error'">
					{{ item.have_link ? "mdi-check-circle" : "mdi-close" }}</v-icon
				>
			</template>
			<template v-slot:item.consented="{ item }">
				<div>
					<v-icon v-if="item.consented" color="success">
						mdi-check-circle
					</v-icon>
					<chase-or-check-consent
						v-else
						:value="item.appointment_participant"
					></chase-or-check-consent>
				</div>
			</template>
			<template v-slot:item.last_email="{ item }">
				<last-comms v-if="item.user" :value="item.user_id"></last-comms>
			</template>
			<template v-slot:item.last_sms="{ item }">
				<last-comms
					v-if="item.user"
					:value="item.user_id"
					type="message"
				></last-comms>
			</template>
		</v-data-table>
	</v-sheet>
</template>

<script>
import VideoIcon from "@c/checklist/VideoIcon";
import ChaseButton from "@c/dashboard/ChaseButton.vue";
import ChaseOrCheckConsent from "@c/consent/ChaseOrCheckConsent.vue";
import LastComms from "@c/communication/LastComms.vue";
export default {
	name: "Checklist",
	data: () => {
		return {
			search: "",
			days: 3,
			unconfirmed_only: true,
			unconsented_only: true,
			unlinked_only: true,
		};
	},
	components: {
		VideoIcon,
		ChaseOrCheckConsent,
		LastComms,
		ChaseButton
	},
	computed: {
		appointments() {
			return this.$store.getters["appointments/checklist"](this.days);
		},
		headers() {
			return [
				{ value: "start", text: "Start" },
				{ value: "project", text: "Project" },
				{ value: "user", text: "User" },
				{ value: "status", text: "Status" },
				{ value: "consented", text: "Consented" },
				{ value: "medium", text: "Platform" },
				{ value: "have_link", text: "Have Link" },
				{ value: "last_email", text: "Last Comms" },
				{ value: "last_sms", text: "Last Comms" },
			];
		},
		rows() {
			const self = this;
			return self.appointments.flatMap((appointment) => {
				let appointmentParticipants = self.$store.getters[
					"appointmentParticipants/byAppointment"
				](appointment.id);
				return appointmentParticipants.map((participant) => {
					return {
						start: appointment.start,
						appointment_participant: participant.id,
						status: participant.appointment_status,
						project_id: appointment.project,
						project: self.$store.getters["projects/name"](
							appointment.project
						),
						video_medium: appointment.video_medium,
						have_link: appointment.participation_link ? true : false,
						consented: participant.consented,
						user_id: participant.user,
						user: self.$store.getters["users/name"](participant.user),
						participation_link: appointment.participation_link,
						is_soon: self.isSoon( appointment.start )
					};
				});
			});
		},
		items() {
			const self = this;
			let rows = self.rows;
			rows = rows.filter( row => {
				let match = false;
				if( !self.unconsented_only && !self.unconfirmed_only && !self.unlinked_only ){
					return true;
				}  
				if( self.unconfirmed_only ){
					if( row.status !== "confirmed" ){
						match = true;
					}
				}
				if( self.unconsented_only ){
					if( !row.consented ){
						match = true;
					}
				}
				if( self.unlinked_only ){
					if( !row.participation_link ){
						match = true;
					}
				}
				return match;
			})
			return rows;
		},
	},
	methods: {
		isSoon(start) {
			let soon = this.mwtimeutils.parseDateToMoment(new Date());
			soon = soon.add(10, "minutes");

			start = this.mwtimeutils.parseDateToMoment(start);
			return start < soon;
		},
		sendConfirmation(id, project) {
			let message = {
				email: "appointment_confirmation",
				values: { appointment_participant: id },
			};
			console.log(project);
			let template = this.$store.getters[
				"projects/confirmationEmailTemplate"
			](project);
			if (template) {
				message.template = template;
			}
			this.$store.dispatch("emails/sendOut", message).then(() => {
				this.$root.$emit("showToast", "Sent!");
			});
		},
	},
	watch: {
		days() {
			this.$store.dispatch("appointments/nextDays", this.days);
		},
	},
	created() {
		this.$store.dispatch("appointments/nextDays", this.days);
	},
};
</script>
