<template>
	<div v-if="last">
		<span :class="statusClass">{{ template }}</span
		><br />
		<small>{{ when }}</small>
	</div>
</template>

<script>
export default {
	name: "LastComms",
	props: {
		value: { type: String, required: true },
		type: { type: String, default: "email" },
	},
	data: () => {
		return {
			last: null,
		};
	},
	computed: {
		template() {
			if (!this.last) {
				return "";
			}
			if (!this.last.template ) {
				return "";
			}
			let template = this.last.template.name
				? this.last.template.name
				: this.last.template;
			return template;
		},
		statusClass() {
			if (!this.last) {
				return "";
			}

			return this.last.delivery.state == "SUCCESS"
				? "success--text"
				: "error--text";
		},
		when() {
			if (!this.last) {
				return "";
			}
			let when = this.last.created_at.toDate();
			return when.toLocaleDateString() + " - " + when.toLocaleTimeString();
		},
	},
	created() {
		const self = this;
		let action = `${this.type}s/fetchLastForUser`;
		self.$store.dispatch(action, this.value).then((res) => {
			self.last = res;
		});
	},
};
</script>
