var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[(_vm.survey)?_c('v-simple-table',[_c('tbody',[_vm._l((_vm.questions),function(question){return [(question.type !== 'matrix')?_c('tr',{key:question.id},[_c('th',[_c('span',{domProps:{"innerHTML":_vm._s(
								_vm.mwsurveyutils.renderQuestionContent(question.id)
							)}})]),_c('td',[_c('response-input',{attrs:{"id":question.id},model:{value:(_vm.response.data[question.id]),callback:function ($$v) {_vm.$set(_vm.response.data, question.id, $$v)},expression:"response.data[question.id]"}})],1)]):_vm._l((question.subquestions),function(subquestion){return _c('tr',{key:((question.id) + "-" + (subquestion.value))},[_c('th',[_c('span',{domProps:{"innerHTML":_vm._s(
									((_vm.mwsurveyutils.renderQuestionContent(
										question.id
									)) + " - " + (subquestion.text))
								)}})]),_c('td',[_c('response-input',{attrs:{"id":question.id},model:{value:(
									_vm.response.data[question.id][subquestion.value]
								),callback:function ($$v) {_vm.$set(_vm.response.data[question.id], subquestion.value, $$v)},expression:"\n\t\t\t\t\t\t\t\t\tresponse.data[question.id][subquestion.value]\n\t\t\t\t\t\t\t\t"}})],1)])})]})],2)]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }