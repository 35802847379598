<template>
		
	<mw-dialog
		@submit="sendReminder"
		submit-btn
		cancel-btn
		title="Are you sure?"
		v-model="dialog"
	>
		<template v-slot:button>
			<v-btn
				x-small
				color="secondary"
				block
				dark
				@click="dialog = true"
			>
				Chase Consent
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			Send email reminding participant to complete their consent form?
		</template>
	</mw-dialog>

</template>

<script>
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "ChaseConsent",
	props: {
		value: { type: String },
	},
	data: () => {
		return {
			dialog: null,
		};
	},
	methods: {
		sendReminder() {
			this.$store
				.dispatch("emails/sendOut", {
					email: "consent_reminder",
					values: { appointment_participant: this.value },
				})
				.then(() => {
					this.dialog = false;
				});
		},
	},
	components: {
		MwDialog,
	},
};
</script>
