<template>
	<v-card flat>
		<v-simple-table v-if="survey">
			<tbody>
				<template v-for="question in questions">
					<tr v-if="question.type !== 'matrix'" :key="question.id">
						<th>
							<span
								v-html="
									mwsurveyutils.renderQuestionContent(question.id)
								"
							></span>
						</th>
						<td>
							<response-input
								v-model="response.data[question.id]"
								:id="question.id"
							></response-input>
						</td>
					</tr>
					<template v-else>
						<tr
							v-for="subquestion in question.subquestions"
							:key="`${question.id}-${subquestion.value}`"
						>
							<th>
								<span
									v-html="
										`${mwsurveyutils.renderQuestionContent(
											question.id
										)} - ${subquestion.text}`
									"
								></span>
							</th>
							<td>
								<response-input
									v-model="
										response.data[question.id][subquestion.value]
									"
									:id="question.id"
								></response-input>
							</td>
						</tr>
					</template>
				</template>
			</tbody>
		</v-simple-table>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn @click="save" color="primary">Save</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import ResponseInput from "@c/responses/ResponseInput";
export default {
	name: "EditConsentButton",
	props: {
		value: { type: String }, // the id of the response,
	},
	data: () => ({}),
	computed: {
		response() {
			return this.$store.state.consents.data[this.value];
		},
		survey() {
			return this.response
				? this.$store.state.surveys.data[this.response.survey]
				: false;
		},
		users() {
			let users = this.$store.getters.getByKey(
				"users",
				"role",
				"participant",
				true
			);
			return this.mwutils.itemsArray(users);
		},
		questions() {
			const self = this;
			return self.survey.questions
				.map((q) => self.$store.state.questions.data[q])
				.filter((a) => a);
		},
	},
	components: {
		ResponseInput,
	},
	methods: {
		save() {
			this.$store.dispatch("consents/patch", {
				id: this.value,
				data: this.response.data,
			});
		},
	},
	watch: {
		response: {
			immediate: true,
			handler(v) {
				if (v) {
					this.$store.dispatch("surveys/fetchById", v.survey);
				}
			},
		},
	},
};
</script>
