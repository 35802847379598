<template>
	<mw-dialog v-model="dialog" small @submit="submit" submit-btn cancel-btn>
        <template v-slot:button>
        <v-btn :small="!xSmall" :x-small="xSmall" color="secondary" block dark @click="dialog = true">Chase User</v-btn>
        </template>
        <template v-slot:dialog-body>
            This will send a message to the participants phone and email reminding them of the session
        </template>
    </mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog"
export default {
	name: "ChaseButton",
	props: {
		value: { type: String },
		xSmall: { type: Boolean },
	},
    data: () => {
        return {
            dialog: false
        }
    },
    methods: {
        submit(){
            const self = this;
            let values = {appointment_participant: self.value};
                self.$store.dispatch("emails/sendOut", {email: "chase_participant", values}).then(() => {
					self.$root.$emit("showToast", "Email Sent");
				})
				.catch((e) => {
					self.$root.$emit("showToast", e, true);
				});
                self.$store.dispatch("messages/sendOut", {sms: "chase_participant", values}).then(() => {
					self.$root.$emit("showToast", "SMS Sent");
					self.dialog = false;
				})
				.catch((e) => {
					self.dialog = false;
					self.$root.$emit("showToast", e, true);
				});
            
        }
    },
    components: {
        MwDialog
    }
};
</script>
